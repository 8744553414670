import Vue from 'vue'
import App from './App.vue'
import router from './router'

//=== css ===
import "@/assets/css/style.css"
import "@/assets/css/sty.css"
import "@/assets/css/static.css"
import "@/assets/css/reset.css"
import "@/assets/css/preloader.css"
import "@/assets/css/elements.css"
import "@/assets/css/swiper.min.css"
import "@/assets/css/swiper-bundle.min.css"
import "@/assets/css/aos.css"

//=== jquery ===
import $ from 'jquery'
Vue.prototype.$ = $;

Vue.prototype.$bus = new Vue();

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
