import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  //=== 首頁 ===
  {
    path: '/',
    name: 'Index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Index" */ '../views/Index.vue')
  },
  //=== 山海誌內頁 單獨使用 ===
  // {
  //   path: '/Mountain',
  //   name: 'Mountain',
  //   component: () => import(/* webpackChunkName: "Mountain" */ '../views/Mountain.vue')
  // },
  // {
  //   path: '/MountainVideo',
  //   name: 'MountainVideo',
  //   component: () => import(/* webpackChunkName: "MountainVideo" */ '../views/MountainVideo.vue')
  // },
  {
    path: '*',//要是路徑沒有ID，是 path 這樣的話，就設定到哪一頁
    redirect: to => {
      return { path: '/', query: {} }
    }
    // redirect: '/'
  },
  //=== 內頁列表 共用 ===
  {
    path: '/InfoList',//要是路徑沒有ID，是 path 這樣的話，就設定到哪一頁
    redirect: '/'
  },
  {
    path: '/InfoList/:id',
    name: 'InfoList',
    component: () => import(/* webpackChunkName: "Video" */ '../views/InfoList/Index.vue')
  },
  //=== 影片列表 ===
  {
    path: '/Video',//要是路徑沒有ID，是 path 這樣的話，就設定到哪一頁
    redirect: '/'
  },
  {
    path: '/Video/:id',
    name: 'Video',
    component: () => import(/* webpackChunkName: "Video" */ '../views/Video/Index.vue')
  },
  //=== 搜尋 ===
  {
    path: '/Src',
    name: 'Src',
    component: () => import(/* webpackChunkName: "Src" */ '../views/Src.vue')
  },
]

const router = new VueRouter({
  mode: "history",//去除掉連結＃
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})

export default router
